<template>
  <div class="manage-project-sales-legal-clients">
    <h2 class="my-3 mx-2">Manage Clients</h2>

    <!-- ============================= Filter ============================ -->
    <filter-panel
      v-model="isFilterActive"
      @clear="clearFilter"
      @filter="filterData"
    >
      <div class="row p-2">
        <fd-input
          v-model="filter['appUser:name[partial]']"
          class="col-12 sm-col-6 md-col-4 px-1 mb-2"
          label="Buyer Name"
          type="text"
          @keyup.enter.native="filterData"
        >
        </fd-input>
      </div>
    </filter-panel>
    <!-- ========================= Table Listing ========================= -->
    <div class="card">
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="clientTableColumns"
        :rows="clientTableData"
        :totalRows="clientTablePagination.total"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        :sort-options="{
          enabled: false
        }"
        @on-row-click="toClientDetail"
      >
        <div slot="emptystate">
          <no-data message="There is no client at the moment."></no-data>
        </div>
        <div slot="table-actions" class="p-1">
          <!-- <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button> -->
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- Table Buttons -->
          <span v-if="props.column.field == 'actions'" @click.stop> </span>
          <span v-else-if="props.column.field == 'SPAStatus'">
            <legal-doc-status
              :status="
                props.row.projectPurchaseLawyer.salesPurchaseAgreementStatus
              "
              statusClass="w-100"
            ></legal-doc-status>
          </span>
          <span v-else-if="props.column.field == 'LAStatus'">
            <legal-doc-status
              :status="props.row.projectPurchaseLawyer.loanAgreementStatus"
              statusClass="w-100"
            ></legal-doc-status>
          </span>
          <!-- ======================== AGENT INFO ======================= -->
          <span
            v-else-if="
              props.column.field == 'assignedAgent' &&
              !_.isEmpty(props.row.agent)
            "
            @click.stop
          >
            <p>{{ props.row.agent.name }}</p>
            <span class="d-flex align-items-center">
              <i class="fas fa-phone mr-1"></i
              >{{ $getUserPhoneNumber(props.row.agent.user) }}
            </span>
            <span class="d-flex align-items-center">
              <i class="fas fa-envelope mr-1"></i
              >{{ props.row.agent.user.email }}
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {
    LegalDocStatus: () =>
      import("@/components/GlobalComponents/Shared/Lawyer/LegalDocStatus")
  },
  mixins: [vueGoodTable],
  props: {
    getClientsAPI: {
      type: Function,
      required: true
    },
    detailRouteName: {
      type: String,
      required: true,
      default: "ManageClientsDetail"
    }
  },
  data: function () {
    return {
      clientTableData: [],
      clientTablePagination: {},
      clientTableColumns: [
        {
          label: "SPA Status",
          field: "SPAStatus",
          width: "160px"
        },
        {
          label: "LA Status",
          field: "LAStatus",
          width: "160px"
        },
        {
          label: "Buyer Name",
          field: "projectPurchaseReservation.purchaserName1"
        },
        {
          label: "Buyer Contact No.",
          field: "projectPurchaseReservation.phoneContact"
        },
        {
          label: "Buyer Email",
          field: "projectPurchaseReservation.email"
        },
        {
          label: "Agent",
          field: "assignedAgent"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "appUser:name[partial]": ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getAllClients();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ page: params.currentPage });
      this.getAllClients().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ perPage: params.currentPerPage });
      this.getAllClients().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toClientDetail(event) {
      let id = event.row.id;
      this.$router.push({
        name: this.detailRouteName,
        params: {
          id: id
        }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.$store.commit("setIsLoading", true);

      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      this.getAllClients().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    clearFilter() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllClients().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    // ========================== API Related =========================
    async getAllClients() {
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.getClientsAPI({
          queries: {
            ...filteredParam
          },
          page: this.tableParams.page,
          limit: this.tableParams.perPage
        });

        this.clientTableData = this._.cloneDeep(data.data);
        this.clientTablePagination = this._.cloneDeep(data.meta.pagination);
        return;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>

<style lang="scss"></style>
