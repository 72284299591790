<template>
  <div class="manage-project-sales-legal-process">
    <div class="container fluid">
      <legal-client-table
        v-if="!_.isEmpty(API)"
        :getClientsAPI="API.getClients"
        :detailRouteName="getDetailRouteName()"
      ></legal-client-table>
    </div>
  </div>
</template>

<script>
import LegalClientTable from "@/components/GlobalComponents/Shared/Lawyer/LegalClientTable";
import { projectLegalProcess as lawyerAPI } from "@/api";

export default {
  components: {
    LegalClientTable
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { lawyerAPI, API: {} };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = lawyerAPI.getProjectLawyerAPI(this.isAssigned);
    },
    getDetailRouteName() {
      return this.isAssigned
        ? "ManageAssignedProjectLegalProcessDetail"
        : "ManageProjectLegalProcessDetail";
    }
  }
};
</script>

<style lang="scss">
</style>

